/* Add these styles to your CSS or in-line styles */

.custom-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid #ddd; /* Border color */
    padding: 8px;
    text-align: left;
  }
  
  .custom-table th {
    background-color: #f2f2f2; /* Header background color */
  }
  
  .sort-indicator {
    display: inline-block;
    margin-left: 5px;
    width: 0;
    height: 0;
    border-style: solid;
  }
  
  .sort-indicator.asc {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #000 transparent;
  }
  
  .sort-indicator.desc {
    border-width: 5px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
  }